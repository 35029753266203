/* -------------------------------------------------------------------------- */
/*                                  Anchor JS                                 */
/* -------------------------------------------------------------------------- */

const anchors = new window.AnchorJS();
anchors.options = {
  icon: '#',
};
anchors.add('[data-anchor]');

