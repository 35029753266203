
/* -------------------------------------------------------------------------- 
|                                 Rellax js                                 
/* -------------------------------------------------------------------------- */

const rellaxInit = () =>{
    return window.Rellax && new window.Rellax('[data-parallax]', {
    });
}

export default rellaxInit

